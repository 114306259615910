import React, {Suspense, useRef, useState} from 'react';
import {Canvas, useFrame} from '@react-three/fiber';
import { OrbitControls, Stage, useGLTF} from '@react-three/drei';
import gltFile from './untitled.glb';
import first from './assets/1.jpg'
import second from './assets/2.jpg'
import third from './assets/3.jpg'
import fourth from './assets/4.jpg'
import fifth from './assets/5.jpg'
import sixth from './assets/6.jpg'
import seventh from './assets/7.jpg'
import eigth from './assets/8.jpg'
import cat from './assets/cat.jpg'
import heart from './assets/heart.png'
import broken from './assets/broken-heart.png'
import yaay from './assets/yaay.jpg'
import gif from './assets/bca4c235c5246737e5fd8a24fac5a787.gif'
const Model = () => {
    const modelRef = useRef();
    const { scene } = useGLTF(gltFile, true);
    let frameCount = 0;
    const beatInterval = 60; // Number of frames for one beat
    scene.traverse((child) => {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });
    useFrame(() => {
        frameCount++;
        // Quick expansion and contraction
        let scale = 1;
        if (frameCount < beatInterval / 6) { // Speed of expansion
            scale = 1 + 0.05 * (frameCount / (beatInterval / 6));
        } else if (frameCount < beatInterval / 3) { // Speed of contraction
            scale = 1.05 - 0.05 * ((frameCount - beatInterval / 6) / (beatInterval / 6));
        }

        if (frameCount >= beatInterval) {
            frameCount = 0;
        }

        modelRef.current.scale.set(scale, scale, scale);
    });

    return(
        <mesh>
            <primitive ref={modelRef} object={scene} position={[0, 10, 0]}/>;
        </mesh>
    )
};
const Heart = () => {
    const [will, setWill] = useState(true);
    const [change, setChange] = useState(false);
    return (
        <div style={{ overflowX:'hidden'}}>
            <div style={{width: '100vw', height: '100vh', background: '#f0f0f0', position: 'relative', overflowX:'hidden'}}>
                <Canvas>
                    <Stage>
                        <Suspense fallback={null}>
                            <OrbitControls enableZoom={false}/>
                            <Model/>
                        </Suspense>
                    </Stage>
                </Canvas>
            </div>
            <div style={{
                width: '100vw',
                height: '100vh',
                zIndex: '10',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow:'hidden'
            }}>
                <div style={{ position:'relative', overflow:'hidden', width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent: 'space-around', alignItems:'center' }}>
                    <div style={{ width:'100%', height:'50%', display:'flex', justifyContent: 'space-around', alignItems:'center' }}>
                        <div  style={{ width:'25%', height:'100%', backgroundImage:`url(${first})`, backgroundSize:'cover', backgroundPosition:'center' }}/>
                        <div  style={{ width:'25%', height:'100%', backgroundImage:`url(${second})`, backgroundSize:'cover', backgroundPosition:'center' }}/>
                        <div  style={{ width:'25%', height:'100%', backgroundImage:`url(${third})`, backgroundSize:'cover', backgroundPosition:'center' }}/>
                        <div  style={{ width:'25%', height:'100%', backgroundImage:`url(${fourth})`, backgroundSize:'cover', backgroundPosition:'center' }}/>
                    </div>
                    <div style={{width: '100%', height:'50%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{
                            width: '25%',
                            height: '100%',
                            backgroundImage: `url(${fifth})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}/>
                        <div style={{
                            width: '25%',
                            height: '100%',
                            backgroundImage: `url(${sixth})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}/>
                        <div style={{
                            width: '25%',
                            height: '100%',
                            backgroundImage: `url(${seventh})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}/>
                        <div style={{
                            width: '25%',
                            height: '100%',
                            backgroundImage: `url(${eigth})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}/>
                    </div>
                    <h1 style={{color:'white',backgroundColor:'black', mixBlendMode:'darken', zIndex:'10', position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)', margin:'0', fontSize:'250px'}}>Miłość</h1>
                </div>
            </div>
            <div style={{
                width: '100vw',
                height: '100vh',
                zIndex: '10',
                display: 'flex',
                flexDirection:'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow:'hidden'
            }}>
                <div style={{ width:'500px', height:'500px', backgroundImage:`url(${ change ? gif : cat})`, backgroundSize:'contain'}} />
                <div>
                    {
                        change ?
                            <img src={yaay} style={{ width:'500px', height:'auto', marginTop:'20px'}} alt=""/>
                            :
                            <>
                                <h1 style={{width: '100%', textAlign: 'center'}}>Zostaniesz moją walentynką?</h1>
                                <div style={{
                                    width: '500px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <button
                                        onClick={() => setChange(true)}
                                        style={{
                                            border: '3px solid #d6453e',
                                            outline: 'none',
                                            background: 'none',
                                            margin: '5px',
                                            borderRadius: '50px',
                                            color: '#d6453e',
                                            padding: '15px 30px',
                                            fontSize: '25px',
                                            fontWeight: '700',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >Tak <img src={heart} style={{width: '25px', height: '25px', marginLeft: '5px'}}
                                              alt=""/>
                                    </button>
                                    {
                                        will ?
                                            <button
                                                onClick={() => setWill(false)}
                                                style={{
                                                    border: '3px solid #d6453e',
                                                    outline: 'none',
                                                    background: 'none',
                                                    margin: '5px',
                                                    borderRadius: '50px',
                                                    color: '#d6453e',
                                                    padding: '15px 30px',
                                                    fontSize: '25px',
                                                    fontWeight: '700',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}
                                            >Nie <img src={broken}
                                                      style={{width: '25px', height: '25px', marginLeft: '5px'}}
                                                      alt=""/>
                                            </button>
                                            : null
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
        ;
};

export default Heart;
